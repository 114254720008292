<template>
  <div class="container">
    <el-dialog title="创建群" width='700px' v-model="dialogVisible">
        <el-form :model="ruleForm" :rules="rules" ref="ruleFormRef" label-width="150px" class="ruleForm">
            <el-form-item label="群主：" prop="robot">
                <el-select v-model="ruleForm.robot" value-key="RobotID" placeholder="请选择一个助手作为群主" filterable>
                  <el-option v-for="item in robots" :key="item.RobotID" :label="item.NickName" :value="item"></el-option>
                </el-select>
                <div class="tips">一个助手最多可开通40个群，每天最多可创建30个群</div>
            </el-form-item>
            <el-form-item label="群名称：" prop="groupName">
                <el-input v-model="ruleForm.groupName" placeholder="请输入群名称" :maxlength="30" show-word-limit/>
            </el-form-item>
            <el-form-item label="群成员及管理员：" prop="group">
               <div class="item-wrap">
                    <el-button type="primary" @click="selectGroup">选择群成员</el-button>
                    <div class="item-group-count">
                        已选择(<span>{{ ruleForm.group.length || 0 }}</span>)个群成员
                    </div>
                </div>
                <div class="tips">最多选择3名群成员，将自动邀请进群并设置为管理员</div>
            </el-form-item>
        </el-form>

        
        <template #footer>
              <div class="dialog-footer">
                 <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
                 <el-button @click="dialogVisible = false">取 消</el-button>
              </div>
          </template>
    </el-dialog>
  </div>
   <GroupSelectCop ref="groupSelectDialog" @onConfirm="chooseGroupAction" />
</template>


<script>
import { nextTick, onMounted, reactive, ref, unref,watch } from "vue";
import {GroupModifyChatName} from '@/helper/group'
import { ElMessageBox,ElMessage } from 'element-plus';
import GroupSelectCop from "@/components/GroupSelectCop";
import {GetRobotSimpleList} from '@/helper/robot'
import {GroupCreateGroupChat} from '@/helper/group'
import _ from "lodash";
export default {
  name: "OpenGroupCop",
  emits:['onConfirm'],
  components: {
    GroupSelectCop,
  },
  setup(props, context) {
    const dialogVisible = ref(false);
    const robots=ref([])
    const ruleFormRef = ref(null);
    const groupSelectDialog=ref(null)
    const ruleForm = reactive({
      robot: {},
      groupName:"",
      group:[]
    });
    const rules = reactive({
      robot: [
        { required: true,validator: validateRobot, trigger: "change" },
      ],
      groupName:[
        { required: true, message: "请填写群名称", trigger: "blur" },
      ],
    });

    onMounted(()=>{
      GetRobotSimpleList({Name:""}).then(res=>{
        robots.value=res.List
        for(let item of robots.value){
          item.NickName=item.NickName+'('+item.WxAlias+')'
        }
      })
    })
    watch(()=>ruleForm.robot.SerialNo,(curVal,prevVal)=>{
      if(ruleForm.robot.SerialNo==curVal){
        ruleForm.group=[]
      }
    })

    //验证
    function validateRobot(rule, value, callback) {
      if (JSON.stringify(ruleForm.robot)=='{}') {
        callback(new Error("请选择一个助手"));
      } else {
        callback();
      }
    }
    function initCop() {
      dialogVisible.value = true;
      resetData()
      nextTick(()=>{
        ruleFormRef.value.clearValidate('robot');
        ruleFormRef.value.clearValidate('groupName');
      })
    }
    
    function resetData() {
        ruleForm.robot={}
        ruleForm.groupName="",
        ruleForm.group=[]
    }
   
    /*****************点击事件*********************/
    //选择群
    function selectGroup() {
        groupSelectDialog.value.initCop(4,ruleForm.group,true,ruleForm)
    }
    
    //确定群
    function chooseGroupAction(arr) {
      if (arr && arr.length > 0) ruleFormRef.value.clearValidate("group");
      ruleForm.group = arr;
    }
  
    //确定表单
    const submitForm = _.debounce(
      async () => {
        const form = unref(ruleFormRef);
        if (!form) return;
        try {
          await form.validate();
          let param={
            RobotSerialNo:ruleForm.robot.SerialNo,
            GroupName:ruleForm.groupName,
            MemberSerialNos:ruleForm.group.map(item=>item.MemberSerialNo)
          }
          GroupCreateGroupChat(param).then(res=>{
            ElMessage({
              type: "success",
              message: "创建成功",
            });
            dialogVisible.value = false;
            context.emit("onConfirm");
          })
        } catch (error) {
          console.log(error);
        }
      },
      1000,
      {
        'leading': true, //在延迟开始前立即调用事件
        'trailing': false, //在延时结束后不调用,保证事件只被触发一次
      }
    );
    return {
      initCop,
      dialogVisible,
      ruleFormRef,
      ruleForm,
      rules,
      submitForm,
      robots,
      groupSelectDialog,
      validateRobot,
      selectGroup,
      chooseGroupAction
    };
  }
};
</script>
<style lang='scss' scoped>
.ruleForm {
  // width: 400px;
  margin: 20px 20px 0;
  .el-select {
    width: 100%;
  }
  p {
    font-size: 12px;
    color: #bbb;
  }
  .item-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    .item-group-count {
      margin: 0 10px;
    }
    span {
      color: $color-common;
    }
  }
}
.tips{
    font-size: 12px;
    color: #ccc;
}
</style>