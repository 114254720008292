<template>
  <div>
    <el-dialog title="开通设置" v-model="dialogVisible" width="30%">
      <span>您可以先为群打标签或者直接开通</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="createGroupAction"
            >打群标签</el-button
          >
          <el-button type="primary" @click="operationAction"
            >直接开通</el-button
          >
          <el-button @click="dialogVisible = false">取 消</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, ref, onMounted } from "vue";
import { ElMessageBox, ElMessage } from "element-plus";
import { SetOpenGroups } from "@/helper/group.js";
export default {
  setup(props, context) {
    const dialogVisible = ref(false);
    const groups = ref([]);
    const tags = ref([]);
    function initCop(row) {
      groups.value = row.map((item) => item.GroupID);
      row.forEach((item) => {
        tags.value=item.GroupTagList.map(item2=>item2.TagID)
      });
      dialogVisible.value = true;
    }

    /********************点击事件**************************/
    //打群标签
    function createGroupAction() {
      dialogVisible.value = false;
      context.emit("onCreatefirm");
    }

    //直接开通
    function operationAction() {
      let param = {
        GroupID: groups.value || []
      };
      SetOpenGroups(param).then((res) => {
        context.emit("onConfirm");
        dialogVisible.value = false;
      });
    }
    return {
      dialogVisible,
      initCop,
      createGroupAction,
      operationAction,
      groups,
      tags,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>