<template>
  <div>
    <div class="table-head">
      <div class="head-title">
        <div class="title">开通流程</div>
        <div class="head-desc">
          <p>
            在<router-link to="robotpage">
              <el-button type="text">“管理助手”</el-button></router-link
            >中扫描助手二维码（助手只有以扫二维码方式添加好友才会自动通过），添加成功后将助手拉进群内，在群内发送任意一段文字后在本页面批量开群。开群成功后可在“群明细”中管理已经开通的群。
          </p>
          <p style="color: red">*请勿在同一个群内拉入多个助手。</p>
        </div>
      </div>
    </div>
    <div class="line-height"></div>
    <div class="table-head">
      <div class="table-search">
        <el-space>
          <span>群名称:</span>
          <el-input
            v-model="searchOption.key"
            placeholder="请输入群名称"
          ></el-input>
        </el-space>
        <div>
          <el-button type="primary" @click="searchAction">搜索</el-button>
          <el-button @click="resetAction">重置</el-button>
        </div>
      </div>
    </div>
    <div class="line-height"></div>
    <div class="table-box">
      <div class="list-title">
        <div>
          待开通群（共 <span>{{ tableData.length || 0 }}</span
          >个群）
        </div>
        <div>
          <el-button type="primary" @click="openAction">开通群</el-button>
          <el-button type="primary" @click="createAction">创建群</el-button>
        </div>
      </div>

      <el-table
        :data="tableData"
        ref="multipleTable"
        class="tablessp"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        border
      >
        <el-table-column
          type="selection"
          width="40"
          :allselect="false"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="GroupName" label="群名称"></el-table-column>
        <el-table-column
          prop="RobotNickName"
          label="助手名称"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="EnterTime"
          label="助手进群时间"
          align="center"
        ></el-table-column>
        <el-table-column prop="FatherNickName" label="邀请人" align="center">
          <template #default="scope">
            {{ scope.row.FatherNickName || "-" }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80">
          <template #default="scope">
            <!-- <el-button type="primary" @click="checkDetail(scope.row)"
              >编辑</el-button
            > -->
            <el-button type="primary" @click="checkOrder(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="foot-wrap">
        <div>
          <el-checkbox
            v-model="selectAll"
            class="selectAll"
            :checked="selectAll"
            @change="changeSelectAll()"
            >全选</el-checkbox
          >
        </div>
      </div>
    </div>
    <GroupNewsDialog
      ref="groupNews"
      @onCreatefirm="showTagCopAction"
      @onConfirm="openSuccessAction"
    ></GroupNewsDialog>
    <GroupFieldCop ref="groupFieldRef" @onConfirm="openSuccessAction" />
    <OpenGroupCop ref="openGroupRef" @onConfirm="openGroupSuccessAction"/>
  </div>
</template>

<script>
import { reactive, ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import TableFoot from "@/components/TableFoot";
import GroupNewsDialog from "@/components/GroupManager/GroupNewsDialog.vue";
import GroupFieldCop from "@/components/GroupFieldCop";
import OpenGroupCop from "@/components/GroupManager/OpenGroupCop"
import { ElMessageBox, ElMessage } from "element-plus";
import { GetUnopenList, DelGroupList,DeleteUnopenGroup} from "@/helper/group.js";
import tools from "@/assets/js/tools";
export default {
  components: {
    TableFoot,
    GroupNewsDialog,
    GroupFieldCop,
    OpenGroupCop
  },
  setup() {
    const router = useRouter();
    const tableData = ref([]);
    const groupNews = ref(null);
    const selectList = ref([]);
    const groupFieldRef = ref(null);
    const multipleTable = ref(null);
    const openGroupRef=ref(null)
    const selectAll = ref(false);
    onMounted(() => {
      searchAction();
    });

    /**********************点击事件***************************/
    //创建群
    function createAction() {
      openGroupRef.value.initCop()
    }
    //创建成功
    function openGroupSuccessAction() {
      searchAction();
    }

    //点击开通
    function openAction() {
      let selId = selectList.value;
      if (selectList.value.length > 0) {
        groupNews.value.initCop(selId);
      } else {
        ElMessage({
          type: "error",
          message: "请至少选择一个群",
        });
      }
    }
    //展示标签弹框
    function showTagCopAction() {
      groupFieldRef.value.initCop(3, [], 1, selectList.value);
    }

    //开通成功
    function openSuccessAction() {
      ElMessage({
        type: "success",
        message: "开通成功",
      });
      searchAction();
    }
    //全选
    function changeSelectAll() {
      multipleTable.value.toggleAllSelection();
      if (selectData.value.length >= tableData.value.length) {
        selectAll.value = false;
      } else {
        selectAll.value = true;
      }
    }
    /**********************搜索***************************/
    const searchOption = reactive({
      key: "",
    });
    function handleSelectionChange(val) {
      // console.log(val)
      selectList.value = val;
      if (selectList.value.length >= tableData.value.length) {
        selectAll.value = true;
      } else {
        selectAll.value = false;
      }
    }
    //执行搜索
    function searchAction() {
      let param = {
        Name: searchOption.key,
      };
      GetUnopenList(param).then((res) => {
        tableData.value = res.List;
      });
    }

    //重置条件
    function resetAction() {
      searchOption.key = "";
      searchAction();
    }

    //删除
    function checkOrder(row) {
      let id = row.GroupID;
      ElMessageBox.confirm(`确认要删除当前群？`, "确认提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
          DeleteUnopenGroup({ GroupID: id }).then((res) => {
            console.log(res);
            ElMessage({
              type: "success",
              message: "删除成功!",
            });
            searchAction();
          });
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "已取消",
          });
        });
    }

    /****************TableFoot*******************/
    const pageObj = reactive({
      TotalRow: 0,
      PageIndex: 1,
      PageSize: 10,
    });
    //页码改变
    function changePage(index) {
      pageObj.PageIndex = index;
      searchAction();
    }
    function changeSize(size) {
      pageObj.PageSize = size;
      pageObj.PageIndex = 1;
      searchAction();
    }

    return {
      tableData,
      searchOption,
      resetAction,
      searchAction,
      changePage,
      changeSize,
      pageObj,
      openAction,
      handleSelectionChange,
      selectList,
      groupNews,
      groupFieldRef,
      showTagCopAction,
      openSuccessAction,
      openGroupSuccessAction,
      checkOrder,
      multipleTable,
      changeSelectAll,
      selectAll,
      createAction,
      openGroupRef
    };
  },
};
</script>

<style lang="scss" scoped>
.head-title {
  .title {
    font-size: 14px;
    font-weight: bold;
    padding: 5px 10px;
    border-bottom: 1px solid #ccc;
  }
  p:first-child {
    font-size: 14px;
    line-height: 24px;
    margin: 5px 10px 0;
  }
  p:last-child {
    font-size: 14px;
    line-height: 24px;
    margin: 0 10px;
  }
}
.foot-wrap {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  .selectAll {
    margin: 0 10px;
  }
}
.list-title div span {
  color: $color-common;
}
:deep().el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: $color-common;
  border-color: $color-common;
}
</style>